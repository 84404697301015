import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Layout from 'components/Layout/Layout';
import PrivateRoute from 'components/PrivateRoute';
import { ENGAGE_ADMIN_ROLES } from 'utils/roles';
import useGetPagesForNav from 'hooks/CustomPages/useGetPagesForNav';
import { QuizProvider } from 'contexts/quizContext';
import { TrainingAdminProvider } from './EngageAdmin/Dashboard/context';

const lazy = path => {
  return async () => {
    const Component = await import(`../${path}`);
    return { Component: Component.default };
  };
};

function useRouter() {
  const { loading, customPages, rootCustomPages, sidebars } = useGetPagesForNav();

  return createBrowserRouter([
    {
      path: '/',
      element: <Layout loading={loading} rootCustomPages={rootCustomPages} />,
      children: [
        /** core pages */
        { path: '/terms-and-conditions', lazy: lazy('routes/TermsAndConditions') },
        { path: '/user-badge/:userBadgeId', lazy: lazy('routes/MyResults/UserBadge') },
        { path: '/transcripts/:userId/badges', lazy: lazy('routes/MyResults/PublicUserBadges') },
        { path: '/module/:moduleId', lazy: lazy('routes/Module') },

        /** auth */
        { path: '/register', lazy: lazy('routes/Register') },
        { path: '/login', lazy: lazy('routes/Login') },
        { path: '/logout', element: <Navigate to='/login' replace={true} /> },
        { path: '/forgot-password', lazy: lazy('routes/ForgotPassword') },
        { path: '/forgot-password/:resetToken', lazy: lazy('routes/ForgotPassword') },

        /** custom pages */
        ...customPages.map(page => {
          return {
            path: page.url,
            lazy: lazy('routes/CustomPage'),
            loader: () => ({
              id: page.id,
              path: page.url,
              title: page.title,
              sidebar: sidebars.find(({ id }) => id === (page.menuRoot || page.id)),
            }),
          };
        }),

        {
          element: <PrivateRoute />,
          children: [
            { path: '/', element: <Navigate to='/dashboard' replace={true} /> },
            { path: '/dashboard', lazy: lazy('routes/Dashboard') },

            /** profile and user data */
            { path: '/profile', lazy: lazy('routes/Profile') },
            { path: '/my-results', lazy: lazy('routes/MyResults/QuizHistory') },
            { path: '/quiz-history', element: <Navigate to='/my-results' replace={true} /> },

            /** the online classroom, modules, quizzes, and the isbe survey */
            { path: '/online-classroom', lazy: lazy('routes/Activities/OnlineClassroom') },
            {
              path: '/quiz',
              Component: QuizProvider,
              children: [
                { path: ':moduleId/intro', lazy: lazy('routes/Activities/Quizzes') },
                { path: ':moduleId/questions', lazy: lazy('routes/Activities/Quizzes/QuizQuestion') },
                { path: ':moduleId/results', lazy: lazy('routes/Activities/Quizzes/QuizResults') },
              ],
            },
            { path: '/isbe-survey/:quizAttemptId', lazy: lazy('routes/IsbeSurvey') },
          ],
        },

        {
          element: <PrivateRoute requiresContract={true} />,
          children: [
            { path: '/events', lazy: lazy('routes/Events') },
            { path: '/learning-campaign/:learningCampaignId/forum', lazy: lazy('routes/DiscussionForum') },
          ],
        },

        /** engage admin */
        {
          path: '/engage-admin',
          element: <PrivateRoute roles={ENGAGE_ADMIN_ROLES} requiresContract={true} />,
          children: [
            {
              Component: TrainingAdminProvider,
              children: [
                { path: '', lazy: lazy('routes/EngageAdmin/Dashboard') },
                { path: 'learning-path/create', lazy: lazy('routes/EngageAdmin/LearningPath/Create') },
                { path: 'learning-path/edit/:id', lazy: lazy('routes/EngageAdmin/LearningPath/Edit') },
                { path: 'inf-learning-template/edit/:id', lazy: lazy('routes/EngageAdmin/LearningPath/Copy') },
                {
                  path: 'learning-campaign/create/',
                  lazy: lazy('routes/EngageAdmin/LearningCampaign/Create'),
                },
                {
                  path: 'learning-campaign/create/:templateId',
                  lazy: lazy('routes/EngageAdmin/LearningCampaign/Create'),
                },
                {
                  path: 'learning-campaign/edit/:learningCampaignId',
                  lazy: lazy('routes/EngageAdmin/LearningCampaign/Edit'),
                },
              ],
            },
          ],
        },

        /** error pages */
        { path: '/404', lazy: lazy('routes/ErrorPages/NotFoundPage') },
        { path: '/403', lazy: lazy('routes/ErrorPages/ForbiddenPage') },
        { path: '/500', lazy: lazy('routes/ErrorPages/ServerErrorPage') },

        /** page not found handler */
        { path: '*', lazy: lazy('routes/ErrorPages/NotFoundPage') },
      ],
    },
  ]);
}

export default useRouter;
