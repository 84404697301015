import React, { useContext, useState, useMemo } from 'react';
import { TrainingAdminContext } from 'routes/EngageAdmin/Dashboard/context';
import { ResultsListHeader, ResultsTab } from 'routes/EngageAdmin/Dashboard/commonStyledElements';
import UsersResultItem from './components/UsersResultItem';
import AllCerficates from './AllCertificates/AllCerficates';
import AllBadges from './AllBadges/AllBadges';

const USER_REPORTS = 'UserReports';
const USER_CERTIFICATES = 'UserCertificates';
const USER_BADGES = 'UserBadges';

function UserReport() {
  const {
    setters: { setUserFilter },
  } = useContext(TrainingAdminContext);
  const [selectedTab, setSelectedTab] = useState(USER_REPORTS);

  const transcriptTabs = [
    { id: USER_REPORTS, text: 'Quiz Attempts' },
    { id: USER_CERTIFICATES, text: 'Certificates' },
    { id: USER_BADGES, text: 'Badges' },
  ];

  const handleChangeTabs = tabId => {
    setUserFilter('');
    setSelectedTab(tabId);
  };

  const ComponentToRender = useMemo(() => {
    switch (selectedTab) {
      case USER_CERTIFICATES:
        return AllCerficates;
      case USER_BADGES:
        return AllBadges;
      case USER_REPORTS:
      default:
        return UsersResultItem;
    }
  }, [selectedTab]);

  return (
    <>
      <ResultsListHeader>
        {transcriptTabs.map(tab => (
          <ResultsTab
            key={tab.id}
            active={selectedTab === tab.id}
            onClick={() => handleChangeTabs(tab.id)}
            tabIndex='0'
          >
            {tab.text}
          </ResultsTab>
        ))}
      </ResultsListHeader>
      <ComponentToRender />
    </>
  );
}

export default UserReport;
